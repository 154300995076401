// import { useCallback } from "react";
// import { useNavigate } from "react-router-dom";
import "./Home.css";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import Vision from "../components/Vision";
import Techsec from "../components/Techsec";
//import Servsec from "../components/Servsec";
import Team from "../components/Team";
import Supportedby from "../components/Supportedby";
import Footer from "../components/Footer";
import Gallery from "../components/Gallery";
import OurUsers from "../components/OurUsers";
import TeamGr from "../components/TeamGr";
//import PopUp from "../components/PopUp";
const Home = () => {


	return (
		<div className="home">
			{/* <PopUp /> */}
			<Navbar />
			<Banner />
			<Vision />
			<Techsec />
			{/* <Servsec /> */}
			{/* <Gallery /> */}
			<p className="ourteam">OUR TEAM</p>
			<TeamGr />
			{/* <Team /> */}
			<Supportedby />
			<OurUsers />
			<Footer />
		</div>
	);
};

export default Home;
